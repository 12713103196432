define("discourse/plugins/retort/discourse/connectors/above-footer/emoji-picker-wrapper", ["exports", "../../lib/retort"], function (_exports, _retort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    setupComponent: function setupComponent(args, component) {
      _retort.default.setPicker(component);
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/retort/discourse/initializers/retort-init", ["exports", "discourse/lib/plugin-api", "discourse/lib/text", "@ember/runloop", "discourse-common/utils/decorators", "@ember/object", "@popperjs/core", "discourse/lib/utilities", "pretty-text/emoji", "discourse/routes/topic", "../lib/retort", "discourse/models/user"], function (_exports, _pluginApi, _text, _runloop, _decorators, _object, _core, _utilities, _emoji, _topic, _retort, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function initializePlugin(api) {
    var _dec, _dec2, _dec3, _obj;

    var _api$container$lookup = api.container.lookup('site-settings:main'),
        retort_enabled = _api$container$lookup.retort_enabled,
        retort_allowed_emojis = _api$container$lookup.retort_allowed_emojis,
        retort_limited_emoji_set = _api$container$lookup.retort_limited_emoji_set;

    var messageBus = api.container.lookup('message-bus:main');

    if (!retort_enabled) {
      return;
    }

    api.decorateWidget('post-menu:before-extra-controls', function (helper) {
      var postId = helper.getModel().id;

      var post = _retort.default.postFor(postId);

      if (_retort.default.disabledFor(postId)) {
        return;
      }

      _retort.default.storeWidget(helper);

      if (!post.retorts) {
        return;
      }

      var retorts = post.retorts.map(function (_ref) {
        var usernames = _ref.usernames,
            emoji = _ref.emoji;
        return helper.attach('retort-toggle', {
          post: post,
          usernames: usernames,
          emoji: emoji
        });
      });
      return helper.h('div.post-retort-container', retorts);
    });
    api.addPostClassesCallback(function (attrs) {
      if (!_retort.default.disabledFor(attrs.id)) {
        return ["retort"];
      }
    });

    if (!_user.default.current()) {
      return;
    }

    api.modifyClass('route:topic', {
      setupController: function setupController(controller, model) {
        _retort.default.initialize(messageBus, model);

        this._super(controller, model);
      }
    });
    api.addPostMenuButton('retort', function (attrs) {
      if (_retort.default.disabledFor(attrs.id)) {
        return;
      }

      return {
        action: 'clickRetort',
        icon: 'far-smile',
        title: 'retort.title',
        position: 'first',
        className: 'retort'
      };
    });
    api.attachWidgetAction('post-menu', 'clickRetort', function () {
      _retort.default.openPicker(this.findAncestorModel());
    });
    api.modifyClass('component:emoji-picker', (_dec = (0, _decorators.default)('retort'), _dec2 = (0, _decorators.default)('retort', 'isActive'), _dec3 = (0, _decorators.observes)("isActive"), (_obj = {
      limited: function limited() {
        return this.retort && retort_limited_emoji_set;
      },
      activeRetort: function activeRetort() {
        return this.retort && this.isActive;
      },
      _setup: function _setup() {
        if (this.retort) {
          this._setupRetort();
        } else {
          this._super();
        }
      },
      _setupRetort: function _setupRetort() {
        if (this.isActive) {
          this.onShowRetort();
        } else {
          this.onClose();
        }
      },
      onShowRetort: function onShowRetort() {
        var _this = this;

        if (!this.limited) {
          this.set("isLoading", true);
        }

        (0, _runloop.schedule)("afterRender", function () {
          document.addEventListener("click", _this.handleOutsideClick);
          var post = _this.post;
          var emojiPicker = document.querySelector(".emoji-picker");
          var retortButton = document.querySelector("\n          article[data-post-id=\"".concat(post.id, "\"] .post-controls .retort"));
          if (!emojiPicker || !retortButton) return false;

          if (!_this.site.isMobileDevice) {
            _this._popper = (0, _core.createPopper)(retortButton, emojiPicker, {
              placement: _this.limited ? "top" : "bottom"
            });
          }

          if (_this.limited) {
            var emojis = retort_allowed_emojis.split('|');
            var basis = 100 / _this._emojisPerRow[emojis.length] || 5;
            emojiPicker.innerHTML = "\n            <div class='limited-emoji-set'>\n              ".concat(emojis.map(function (code) {
              return "<img\n                src=\"".concat((0, _text.emojiUrlFor)(code), "\"\n                width=40\n                height=40\n                title='").concat(code, "'\n                class='emoji' />");
            }).join(''), "\n            </div>\n          ");
            emojiPicker.classList.add("has-limited-set");

            emojiPicker.onclick = function (e) {
              if (e.target.classList.contains("emoji")) {
                _this.emojiSelected(e.target.title);
              } else {
                _this.set('isActive', false);

                _this.onClose();
              }
            };
          } else {
            emojiPicker.querySelectorAll(".emojis-container .section .section-header").forEach(function (p) {
              return _this._sectionObserver.observe(p);
            });
            (0, _runloop.later)(function () {
              _this.set("isLoading", false);

              _this.applyDiscourseTrick(emojiPicker);
            }, 50);
          }
        });
      },
      // Lifted from onShow in emoji-picker. See note in that function concerning its utility.
      applyDiscourseTrick: function applyDiscourseTrick(emojiPicker) {
        var _this2 = this;

        (0, _runloop.schedule)("afterRender", function () {
          if ((!_this2.site.isMobileDevice || _this2.isEditorFocused) && !(0, _utilities.safariHacksDisabled)()) {
            var filter = emojiPicker.querySelector("input.filter");
            filter && filter.focus();
          }

          if (_this2.selectedDiversity !== 0) {
            _this2._applyDiversity(_this2.selectedDiversity);
          }
        });
      },
      onCategorySelection: function onCategorySelection(sectionName) {
        var section = document.querySelector(".emoji-picker-emoji-area .section[data-section=\"".concat(sectionName, "\"]"));
        section && section.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        });
      },
      onFilter: function onFilter(event) {
        var emojiPickerArea = document.querySelector(".emoji-picker-emoji-area");
        var emojisContainer = emojiPickerArea.querySelector(".emojis-container");
        var results = emojiPickerArea.querySelector(".results");
        results.innerHTML = "";

        if (event.target.value) {
          results.innerHTML = (0, _emoji.emojiSearch)(event.target.value.toLowerCase(), {
            maxResults: 10,
            diversity: this.emojiStore.diversity
          }).map(this._replaceEmoji).join("");
          emojisContainer.style.visibility = "hidden";
          results.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest'
          });
        } else {
          emojisContainer.style.visibility = "visible";
        }
      },
      _emojisPerRow: {
        0: 1,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 3,
        7: 3,
        8: 4,
        9: 3,
        10: 5,
        11: 5,
        12: 4,
        13: 5,
        14: 7,
        15: 5,
        16: 4,
        17: 5,
        18: 6,
        19: 6,
        20: 5,
        21: 7,
        22: 5,
        23: 5,
        24: 6
      }
    }, (_applyDecoratedDescriptor(_obj, "limited", [_dec], Object.getOwnPropertyDescriptor(_obj, "limited"), _obj), _applyDecoratedDescriptor(_obj, "activeRetort", [_dec2], Object.getOwnPropertyDescriptor(_obj, "activeRetort"), _obj), _applyDecoratedDescriptor(_obj, "_setup", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_setup"), _obj), _applyDecoratedDescriptor(_obj, "onShowRetort", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onShowRetort"), _obj), _applyDecoratedDescriptor(_obj, "onCategorySelection", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onCategorySelection"), _obj), _applyDecoratedDescriptor(_obj, "onFilter", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onFilter"), _obj)), _obj)));
  }

  var _default = {
    name: 'retort-button',
    initialize: function initialize() {
      (0, _pluginApi.withPluginApi)('0.8.6', function (api) {
        return initializePlugin(api);
      });
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/retort/discourse/lib/retort", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/get-owner"], function (_exports, _ajax, _ajaxError, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.create({
    topic: {
      postStream: {
        posts: []
      }
    },
    initialize: function initialize(messageBus, topic) {
      var _this = this;

      if (this.topic.id) {
        messageBus.unsubscribe("/retort/topics/".concat(this.topic.id));
      }

      this.set('topic', topic);
      messageBus.subscribe("/retort/topics/".concat(this.topic.id), function (_ref) {
        var id = _ref.id,
            retorts = _ref.retorts;

        var post = _this.postFor(id);

        if (!post) {
          return;
        }

        post.setProperties({
          retorts: retorts
        });

        _this.get("widgets.".concat(id)).scheduleRerender();
      });
      var siteSettings = (0, _getOwner.getOwner)(this).lookup("site-settings:main");
      this.set('siteSettings', siteSettings);
    },
    postFor: function postFor(id) {
      return (this.get('topic.postStream.posts') || []).find(function (p) {
        return p.id == id;
      });
    },
    storeWidget: function storeWidget(helper) {
      if (!this.get('widgets')) {
        this.set('widgets', {});
      }

      this.set("widgets.".concat(helper.getModel().id), helper.widget);
    },
    updateRetort: function updateRetort(_ref2, retort) {
      var id = _ref2.id;
      return (0, _ajax.ajax)("/retorts/".concat(id, ".json"), {
        type: 'POST',
        data: {
          retort: retort
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    disabledCategories: function disabledCategories() {
      var categories = this.siteSettings.retort_disabled_categories.split('|');
      return categories.map(function (cat) {
        return cat.toLowerCase();
      }).filter(Boolean);
    },
    disabledFor: function disabledFor(postId) {
      var post = this.postFor(postId);

      if (!post) {
        return true;
      }

      if (!post.topic.details.can_create_post) {
        return true;
      }

      if (post.get('topic.archived')) {
        return true;
      }

      var categoryName = post.get('topic.category.name');
      var disabledCategories = this.disabledCategories();
      return categoryName && disabledCategories.includes(categoryName.toString().toLowerCase());
    },
    openPicker: function openPicker(post) {
      this.set('picker.isActive', true);
      this.set('picker.post', post);
    },
    setPicker: function setPicker(picker) {
      var _this2 = this;

      this.set('picker', picker);
      this.set('picker.emojiSelected', function (retort) {
        return _this2.updateRetort(picker.post, retort).then(function () {
          return picker.set('isActive', false);
        });
      });
    }
  });

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/connectors/above-footer/emoji-picker-wrapper"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[1,[28,\"emoji-picker\",null,[[\"retort\",\"isActive\",\"post\",\"emojiSelected\",\"onEmojiPickerClose\"],[true,[24,[\"isActive\"]],[24,[\"post\"]],[24,[\"emojiSelected\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isActive\"]]],null],false],null]]]],false]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/above-footer/emoji-picker-wrapper"}});
define("discourse/plugins/retort/discourse/widgets/retort-toggle", ["exports", "virtual-dom", "discourse/lib/text", "discourse/widgets/widget", "../lib/retort"], function (_exports, _virtualDom, _text, _widget, _retort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _widget.createWidget)('retort-toggle', {
    tagName: 'button.post-retort',
    buildKey: function buildKey(attrs) {
      return "retort-toggle-".concat(attrs.emoji, "-").concat(attrs.usernames.length);
    },
    defaultState: function defaultState(_ref) {
      var emoji = _ref.emoji,
          post = _ref.post,
          usernames = _ref.usernames;
      return {
        emoji: emoji,
        post: post,
        usernames: usernames
      };
    },
    click: function click() {
      var _this$state = this.state,
          post = _this$state.post,
          emoji = _this$state.emoji;

      _retort.default.updateRetort(post, emoji);
    },
    html: function html() {
      var _this$state2 = this.state,
          emoji = _this$state2.emoji,
          usernames = _this$state2.usernames;
      return [(0, _virtualDom.h)('img.emoji', {
        src: (0, _text.emojiUrlFor)(emoji),
        alt: ":".concat(emoji, ":")
      }), usernames.length > 1 ? (0, _virtualDom.h)('span.post-retort__count', usernames.length.toString()) : '', (0, _virtualDom.h)('span.post-retort__tooltip', this.sentence(this.state))];
    },
    sentence: function sentence(_ref2) {
      var usernames = _ref2.usernames,
          emoji = _ref2.emoji;
      var key;

      switch (usernames.length) {
        case 1:
          key = 'retort.reactions.one_person';
          break;

        case 2:
          key = 'retort.reactions.two_people';
          break;

        default:
          key = 'retort.reactions.many_people';
          break;
      }

      return I18n.t(key, {
        emoji: emoji,
        first: usernames[0],
        second: usernames[1],
        count: usernames.length - 2
      });
    }
  });

  _exports.default = _default;
});

